/* eslint-disable */
import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

export const LandingPageCard = ({ data }) => {
  return (
    <Card
      sx={{
        width: { xs: "full", md: "324px" },
        height: { xs: "125px", md: "207px" },
        m: 2,
        boxShadow: "none",
      }}
    >
      <CardContent>
        <Typography variant="h3" component="div" align="center" color="primary">
          {data.count}
        </Typography>
        <Typography variant="p" component="div" align="center">
          {data.detail}
        </Typography>
      </CardContent>
    </Card>
  );
};
