/* eslint-disable */
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../../context/DataContext";

export const CategoryCourse = () => {
  const { ListCategory } = useData();
  const navigate = useNavigate();

  // state to keep track of the current page
  const [page, setPage] = useState(1);

  // number of items to display per page
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const ITEMS_PER_PAGE = isMobile ? 2 : 8;

  // calculate the starting index and ending index based on the current page and number of items per page
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // slice the list based on the starting and ending index
  const slicedCategoryList = ListCategory?.slice(startIndex, endIndex);

  // total number of pages
  const totalPages = Math.ceil(ListCategory?.length / ITEMS_PER_PAGE);

  // function to handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Typography variant="h4" color="primary" align="center">
        Available Language Course
      </Typography>
      <Container sx={{ my: 5 }} maxWidth={"lg"}>
        <Grid container columns={{ xs: 2, md: 4 }}>
          {slicedCategoryList?.map((data) => (
            <Grid
              item
              xs={1}
              sm={1}
              key={data.id}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "lightgray",
                  borderRadius: "8px",
                },
              }}
              onClick={() => navigate(`/category/${data.id}`)}
            >
              <FlagCard data={data} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ my: 5, display: "flex", justifyContent: "center" }}
      />
    </>
  );
};

const FlagCard = ({ data }) => {
  return (
    <Card
      sx={{
        p: 1,
        m: { xs: 1, md: 3 },
        boxShadow: "none",
        border: "1px solid #BDBDBD",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          height: "133.33px",
          width: "200px",
          maxHeight: "100%",
          maxWidth: "100%",
          objectFit: "contain",
        }}
        image={data.imgUrl}
        title={data.category}
      />
      <CardContent>
        <Typography sx={{ fontSize: { xs: 16, md: 20 } }}>
          {data.category}
        </Typography>
      </CardContent>
    </Card>
  );
};
