/* eslint-disable */
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Badge, Button } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  CheckoutContext,
  CheckoutProvider,
} from "../../context/CheckoutContext";

export const DekstopNav = ({ onLogoutClick }) => {
  const { user } = useAuth();
  const { ListCheckout } = useContext(CheckoutContext);
  return (
    <CheckoutProvider>
      {user ? (
        <>
          <Button component={Link} to="/checkout" color="primary">
            <Badge badgeContent={ListCheckout?.length} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </Button>
          <Button
            variant="text"
            sx={{ mr: { xs: 1, md: 2 } }}
            component={Link}
            to="/myclass"
          >
            My Class
          </Button>
          <Button variant="text" component={Link} to="/invoice">
            Invoice
          </Button>
          <div
            style={{
              width: "20px",
              height: " 0px",
              border: "1px solid #000000",
              transform: "rotate(90deg)",
            }}
          ></div>
          <Button component={Link} to="/profile" color="primary">
            <PersonIcon />
          </Button>
          {user?.role === "admin" && (
            <Button component={Link} to="/adminpanel/course" color="primary">
              <AdminPanelSettingsIcon />
            </Button>
          )}
          <Button component={Link} to="/" color="red" onClick={onLogoutClick}>
            <LogoutIcon />
          </Button>
        </>
      ) : (
        <>
          <Button
            color="primary"
            sx={{ mr: { xs: 1, md: 2 } }}
            variant="contained"
            component={Link}
            to="/login"
          >
            Login
          </Button>
          <Button
            color="secondary"
            variant="contained"
            component={Link}
            to="/register"
          >
            Sign Up
          </Button>
        </>
      )}
    </CheckoutProvider>
  );
};
