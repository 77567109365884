/* eslint-disable */
import { Container, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { Footer } from "../components/Footer";
import { Banner } from "../components/LandingPage/Banner";
import { CategoryCourse } from "../components/LandingPage/CategoryCourse";
import { LandingPageCard } from "../components/LandingPage/LandingPageCard";
import { LandingPageHero } from "../components/LandingPage/LandingPageHero";
import { RecomendedClass } from "../components/RecomendedClass";
import Carousel from "react-material-ui-carousel";
import { useTheme } from "@emotion/react";

export const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [TotalCourse, setTotalCourse] = useState([]);
  const listLandingPage = [
    {
      count: `${TotalCourse?.length}+`,
      detail: "Choose the class you like and get the skills",
    },
    {
      count: "50+",
      detail:
        "Having teachers who are highly skilled and competent in the language",
    },
    {
      count: "10+",
      detail:
        "Many alumni become ministry employees because of their excellent language skills",
    },
  ];
  return (
    <>
      <Banner />
      <Container
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          mt: 5,
          mb: { xs: 5 },
        }}
        maxWidth={"lg"}
      >
        {listLandingPage?.map((data, i) => (
          <LandingPageCard data={data} key={i} />
        ))}
      </Container>

      <Carousel
        sx={{ my: 4, display: { xs: "block", md: "none" } }}
        animation="slide"
        autoPlay
      >
        {listLandingPage?.map((data, i) => (
          <LandingPageCard data={data} key={i} />
        ))}
      </Carousel>

      <Typography variant="h4" color="primary" align="center">
        Recommended Class
      </Typography>
      <RecomendedClass
        item={isMobile ? 2 : 6}
        totalcourse={(totalcourse) => setTotalCourse(totalcourse)}
      />
      <LandingPageHero />
      <CategoryCourse />
      <Footer />
    </>
  );
};
