/* eslint-disable */
import { Alert, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../api/axios";
import { CustomAlert } from "../utils/CustomAlert";

export default function SignUp() {
  const [formData, setFormData] = useState({
    nama: "",
    email: "",
    password: "",
    verifpassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    nama: "",
    email: "",
    password: "",
    verifpassword: "",
  });

  const [Status, setStatus] = useState("");
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // reset the error message for the field
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // validate form sebelum submit
      if (validateForm()) {
        setLoading(true);
        const response = await api.post(`/api/Users/register`, formData);
        setSuccess(response?.data);
        setStatus("");
        setLoading(false);
      }
    } catch (error) {
      setStatus({ message: `${error.response?.data?.message}`, type: "error" });
      setLoading(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.nama) {
      errors.nama = "Please enter your name";
      isValid = false;
    }

    if (!formData.email) {
      errors.email = "Please enter your email";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!formData.password) {
      errors.password = "Please enter a password";
      isValid = false;
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
      isValid = false;
    }

    if (!formData.verifpassword) {
      errors.verifpassword = "Please confirm your password";
      isValid = false;
    } else if (formData.verifpassword !== formData.password) {
      errors.verifpassword = "Passwords do not match";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ marginTop: 8 }}>
        <Typography display="flex" alignItems="center">
          <span
            style={{ color: "#226957", fontSize: "24px", marginRight: "10px" }}
          >
            Lets Join
          </span>{" "}
          <span style={{ color: "#EA9E1F", fontSize: "36px" }}>D'Language</span>
        </Typography>
        <Typography>Please register first</Typography>
        <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : success ? (
          <>
            <Alert sx={{ mt: 2 }} severity="success">
              {success.message}
            </Alert>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                component={Link}
                to="/"
                sx={{ width: "133px", mt: 3, mb: 2 }}
              >
                Back
              </Button>
            </Box>
          </>
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              name="nama"
              type="text"
              label="Name"
              size="small"
              autoFocus
              value={formData.nama}
              onChange={handleChange}
              error={Boolean(formErrors.nama)}
              helperText={formErrors.nama}
            />
            <TextField
              margin="normal"
              fullWidth
              name="email"
              type="email"
              size="small"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              error={Boolean(formErrors.email)}
              helperText={formErrors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type="password"
              name="password"
              size="small"
              label="Password"
              value={formData.password}
              onChange={handleChange}
              error={Boolean(formErrors.password)}
              helperText={formErrors.password}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              name="verifpassword"
              size="small"
              type="password"
              value={formData.verifpassword}
              onChange={handleChange}
              error={Boolean(formErrors.verifpassword)}
              helperText={formErrors.verifpassword}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "133px" }}
                disabled={loading}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        )}
        <Typography sx={{ mt: 3, mb: 2 }} align="center">
          Have account? <Link to="/login">Login here</Link>
        </Typography>
      </Box>
    </Container>
  );
}
