/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { Footer } from "../components/Footer";
import { useAuth } from "../context/AuthContext";

export const MyClassPage = () => {
  const Auth = useAuth();
  const [ListMyClass, setListMyClass] = useState([]);
  const ListCourse = () =>
    ListMyClass.map((course, i) => <ItemClass course={course} key={i} />);

  useEffect(() => {
    const getListSchedule = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/MyClass?id_user=${Auth?.user?.id}`
        );
        console.log(response);
        setListMyClass(response?.data);
      } catch (error) {}
    };

    getListSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Auth?.user?.id]);

  return (
    <div>
      <Stack sx={{ pb: 20 }} minHeight={"80vh"}>
        <Container maxWidth="true">
          <ListCourse />
        </Container>
      </Stack>
      <Footer />
    </div>
  );
};

const ItemClass = ({ course }) => {
  const date = moment(course.schedule);
  const formattedDate = date.format("dddd, D MMMM YYYY");

  return (
    <Stack>
      <Grid container alignItems="center" spacing={2} sx={{ my: 2 }}>
        <Grid item xs={4} sm={3} md={2}>
          <img src={course.imgUrl} width="100%" alt={course.name} />
        </Grid>
        <Grid item xs={8} sm={9} md={10}>
          <Typography variant="body1" color="gray">
            {course.category}
          </Typography>
          <Typography variant="h5">{course.name}</Typography>
          <Typography variant="h6" color="secondary">
            {formattedDate}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </Stack>
  );
};
