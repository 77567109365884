/* eslint-disable */
import { Box, Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { AdminCategory } from "../components/AdminPanel/AdminCategory";
import { AdminCourse } from "../components/AdminPanel/AdminCourse";
import { AdminNav } from "../components/AdminPanel/AdminNav";
import { AdminPayment } from "../components/AdminPanel/AdminPayment";
import { AdminUser } from "../components/AdminPanel/AdminUser";
import { AdminInvoice } from "../components/AdminPanel/AdminInvoice";

export const AdminPanel = () => {
  const { nav } = useParams();

  return (
    <Box sx={{ mx: 2 }}>
      <Grid container columns={{ md: 12 }}>
        <Grid item xs={2}>
          <AdminNav />
        </Grid>
        <Grid item xs={10}>
          {nav === "course" && <AdminCourse />}
          {nav === "category" && <AdminCategory />}
          {nav === "user" && <AdminUser />}
          {nav === "invoice" && <AdminInvoice />}
          {nav === "payment" && <AdminPayment />}
        </Grid>
      </Grid>
    </Box>
  );
};
