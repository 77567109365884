/* eslint-disable */
import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../api/axios";
import { CustomAlert } from "../utils/CustomAlert";

export const NewPassword = ({ page }) => {
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [Success, setSuccess] = useState(null);
  const [Status, setStatus] = useState("");
  const [Loading, setLoading] = useState(false);

  const { email, token } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post(`/api/Users/forgotpassword`, {
        email: Email,
      });
      setSuccess(response?.data);
      setStatus("");
      setLoading(false);
    } catch (error) {
      setStatus({ message: `${error.response?.data?.message}`, type: "error" });
      setLoading(false);
    }
  };

  const handleSubmitCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post(
        `/api/Users/newpassword?email=${email}&token=${token}`,
        {
          password: Password,
          verifpassword: ConfirmPassword,
        }
      );
      setSuccess(response?.data);
      setStatus("");
      setLoading(false);
    } catch (error) {
      setStatus({ message: `${error.response?.data?.message}`, type: "error" });
      setLoading(false);
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        {page === "email" && (
          <>
            <Typography sx={{ fontSize: "24px", mb: 1, color: "#333333" }}>
              Reset Password
            </Typography>
            <Typography sx={{ color: "#4F4F4F", mb: 2 }}>
              Please enter your email address
            </Typography>
            <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />
            {Success ? (
              <>
                <Alert sx={{ mt: 2 }} severity="success">
                  {Success.message}
                </Alert>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    component={Link}
                    to="/"
                    sx={{ width: "133px", mt: 3, mb: 2 }}
                  >
                    Back
                  </Button>
                </Box>
              </>
            ) : (
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  type="email"
                  label="Email"
                  size="small"
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ my: 3 }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to="/login"
                    sx={{ width: "140px", height: "38px", mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: "140px", height: "38px" }}
                    onClick={handleSubmit}
                    disabled={Loading}
                  >
                    Confirm
                  </Button>
                </div>
              </Box>
            )}
          </>
        )}
        {page === "newPassword" && (
          <>
            <Typography>
              <span style={{ fontSize: "24px", color: "#333333" }}>
                Create Password
              </span>
            </Typography>

            {Success ? (
              <>
                <Alert sx={{ mt: 2 }} severity="success">
                  {Success.message}
                </Alert>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    component={Link}
                    to="/login"
                    sx={{ width: "133px", mt: 3, mb: 2 }}
                  >
                    Login
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box component="form" noValidate sx={{ my: 4 }}>
                  <TextField
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                    type="password"
                    id="password"
                    label="New Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                    type="password"
                    id="confirmPassword"
                    label="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Box>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ width: "140px", height: "38px", mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: "140px", height: "38px" }}
                    onClick={handleSubmitCreate}
                    disabled={Loading}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};
