/* eslint-disable */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api/axios";
import { useAuth } from "../context/AuthContext";
import { CustomAlert } from "../utils/CustomAlert";

export default function SignIn() {
  const { login } = useAuth();
  const [Status, setStatus] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // reset the error message for the field
    setFormErrors({ ...formErrors, [name]: "" });
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        const response = await api.post(`/api/Users/login`, formData);
        login(response?.data);
        if (response) {
          api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.token}`;
          api.defaults.headers.post["Content-Type"] = "application/json";
        }
        navigate("/");
        setStatus("");
      }
    } catch (error) {
      setStatus({ message: `${error.response?.data?.message}`, type: "error" });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.email) {
      errors.email = "Please enter your email";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!formData.password) {
      errors.password = "Please enter a password";
      isValid = false;
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Typography sx={{ color: "#226957", fontSize: "24px" }}>
          Welcome Back!
        </Typography>
        <Typography>Please login first</Typography>

        <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            name="email"
            type="email"
            size="small"
            label="Email"
            value={formData.email}
            onChange={handleChange}
            error={Boolean(formErrors.email)}
            helperText={formErrors.email}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            type="password"
            size="small"
            label="Password"
            value={formData.password}
            onChange={handleChange}
            error={Boolean(formErrors.password)}
            helperText={formErrors.password}
          />
          <Typography>
            Forgot Password?{" "}
            <Link to="/forgotPassword" variant="body2">
              Click here
            </Link>
          </Typography>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "133px" }}
            >
              Login
            </Button>
          </div>
          <Typography sx={{ mt: 3, mb: 2 }} align="center">
            Don't have account?{" "}
            <Link to="/register" variant="body2">
              Sign Up here
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
