/* eslint-disable */
import { Box, Stack, Typography } from "@mui/material";

import React from "react";
import { rupiah } from "../utils/FormatIDR";

export const Course = (props) => {
  const { category, title, price, img } = props;
  return (
    <div>
      <Box
        border={"1px solid gray"}
        borderRadius={4}
        display={"flex"}
        flexDirection={"column"}
      >
        <img src={img} alt="" />
        <Box padding={2}>
          <Stack flexGrow={1}>
            <Typography variant="caption" color="gray">
              {category}
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              height={50}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {title}
            </Typography>
          </Stack>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="primary"
            marginTop={4}
          >
            {rupiah(price)}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
