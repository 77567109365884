/* eslint-disable */
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api/axios";
import { Footer } from "../components/Footer";
import { RecomendedClass } from "../components/RecomendedClass";
import { useAuth } from "../context/AuthContext";
import { CheckoutContext } from "../context/CheckoutContext";
import { CustomAlert } from "../utils/CustomAlert";
import { rupiah } from "../utils/FormatIDR";

export const DetailsCoursePage = () => {
  const Auth = useAuth();
  const navigate = useNavigate();

  const { setUpdateCheckout } = useContext(CheckoutContext);

  const [SelectedSchedule, setSelectedSchedule] = useState("");
  const [Status, setStatus] = useState("");
  const [IsLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const { id } = useParams();
  const [SelectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    const getSelectedClass = async () => {
      try {
        const response = await api.get(`/api/Class/GetById?id=${id}`);
        setSelectedClass(response?.data);
        console.log(SelectedClass?.idCategory);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getSelectedClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedClass?.idCategory, id]);

  const isEmpty = (str) => {
    return !str || str.trim().length === 0;
  };

  const handleAddToCart = async () => {
    const user = Auth.user;

    if (isEmpty(SelectedSchedule)) {
      setStatus({ message: "schedule cannot be empty", type: "error" });
      return;
    }

    setIsLoadingCheckout(true);
    try {
      const body = {
        idUser: user?.id,
        idClass: SelectedClass?.id,
        schedule: SelectedSchedule,
      };

      const response = await api.post(`/api/Checkout`, body);

      if (response.status === 200) {
        setStatus({ message: "Add to checkout Succesfully", type: "success" });
        setUpdateCheckout(response);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      if (error.request.status === 409) {
        setStatus({ message: `${error.request.response}`, type: "error" });
        setSelectedSchedule("");
      }
    }
    setIsLoadingCheckout(false);
  };

  const handleBuyNow = async () => {
    const user = Auth.user;

    if (isEmpty(SelectedSchedule)) {
      setStatus({ message: "schedule cannot be empty", type: "error" });
      return;
    }

    if (!user) {
      setStatus({ message: "Logins are required", type: "error" });
      return;
    }
    setIsLoadingCheckout(true);

    try {
      const body = {
        idUser: user?.id,
        idClass: SelectedClass?.id,
        schedule: SelectedSchedule,
      };

      const response = await api.post(`/api/Checkout`, body);

      if (response.status === 200) {
        navigate("/checkout");
        setUpdateCheckout(response);
      }
      console.log(response);
    } catch (error) {
      console.log(error.request.status);
      if (error.request.status === 409) {
        setStatus({ message: `${error.request.response}`, type: "error" });
        setSelectedSchedule("");
      }
    }

    setIsLoadingCheckout(false);
  };

  return (
    <div>
      <Box px={[4, 8]} py={[2, 4]}>
        <Stack direction={{ sm: "column", md: "row" }}>
          <img src={SelectedClass?.imgUrl} alt="" />
          <Stack direction="column" ml={[0, 4]} mt={[2, 0]}>
            <Typography variant="body1">{SelectedClass?.category}</Typography>
            <Typography variant="h5" my={1}>
              {SelectedClass?.name}
            </Typography>
            <Typography variant="h5" color="secondary">
              {rupiah(SelectedClass?.price)}
            </Typography>
            <Schedule />
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleAddToCart}
                sx={{ width: "200px" }}
                disabled={IsLoadingCheckout}
              >
                Add to Cart
              </Button>
              <Button
                variant="contained"
                sx={{ width: "200px" }}
                disabled={IsLoadingCheckout}
                onClick={handleBuyNow}
              >
                Buy Now
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack py={4}>
          <Typography variant="h5">Description</Typography>
          <Typography variant="body1" mt={2}>
            <span
              dangerouslySetInnerHTML={{ __html: SelectedClass?.description }}
            ></span>
          </Typography>
        </Stack>
      </Box>
      <Divider />

      <Container sx={{ my: 5 }} maxWidth={"lg"}>
        <Typography variant="h5" sx={{ color: "green", textAlign: "center" }}>
          Another class for you
        </Typography>
        {SelectedClass && (
          <RecomendedClass
            item={3}
            category={SelectedClass.idCategory}
            id={id}
            onclick={() => setSelectedSchedule("")}
          />
        )}
      </Container>
      <Footer />
      <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />
    </div>
  );

  function Schedule() {
    const handleChange = (event) => {
      setSelectedSchedule(event.target.value);
    };

    return (
      <FormControl sx={{ my: 4, minWidth: 120, maxWidth: 200 }}>
        <Select value={SelectedSchedule} onChange={handleChange} displayEmpty>
          <MenuItem value="">Select Schedule</MenuItem>{" "}
          {SelectedClass?.listSchedule?.map((schedule, index) => {
            const date = moment(schedule);
            const formattedDate = date.format("dddd, D MMMM YYYY");

            return (
              <MenuItem key={index} value={schedule}>
                {formattedDate}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
};
