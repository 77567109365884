/* eslint-disable */
import { createContext, useEffect, useState } from "react";
import api from "../api/axios";
import { useAuth } from "./AuthContext";

export const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
  const [ListCheckout, setListCheckout] = useState([]);
  const [UpdateCheckout, setUpdateCheckout] = useState([]);

  const { user } = useAuth();

  useEffect(() => {
    const getListCheckout = async (e) => {
      try {
        const response = await api.get(
          `/api/Checkout/GetAllById?idUser=${user?.id}`
        );
        setListCheckout(response?.data);
      } catch (error) {}
    };
    if (user?.id) {
      getListCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, setListCheckout, UpdateCheckout]);

  return (
    <CheckoutContext.Provider
      value={{ ListCheckout, setListCheckout, setUpdateCheckout }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
