/* eslint-disable */
import {
  Box,
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api/axios";
import { rupiah } from "../../utils/FormatIDR";

export const AdminInvoice = () => {
  const [ListInvoice, setListInvoice] = useState([]);

  useEffect(() => {
    const getInvoice = async () => {
      try {
        const response = await api.get("/api/Invoice");
        setListInvoice(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 8;
  const totalPages = Math.ceil(ListInvoice?.length / ITEMS_PER_PAGE);
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const slicedListInvoice = ListInvoice?.slice(startIndex, endIndex);

  // function to handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ mx: 2 }}>
      <TableContainer sx={{ my: 1 }}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="center">No Invoice</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Total Course</TableCell>
              <TableCell align="center">Total Price</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedListInvoice?.reverse().map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell align="center">{row.no_invoice}</TableCell>
                <TableCell align="center">
                  {moment(row.created_at).format("D MMMM YYYY")}
                </TableCell>
                <TableCell align="center">{row.qty}</TableCell>
                <TableCell align="center">{rupiah(row.total_price)}</TableCell>

                <TableCell align="center">
                  <Button
                    component={Link}
                    to={`/detailsinvoice/${row.no_invoice}`}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ my: 5, display: "flex", justifyContent: "center" }}
      />
    </Box>
  );
};
