/* eslint-disable */
import { Container, Grid, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Course } from "./Course";
import api from "../api/axios";

export const RecomendedClass = ({
  item,
  category,
  id,
  onclick,
  totalcourse,
}) => {
  const navigate = useNavigate();
  const [ListClass, setListClass] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const getRecomendedClass = async () => {
      try {
        const response = await api.get(
          `/api/Class/GetAllByCategory?id_category=${category}`
        );
        setListClass(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    const getAll = async () => {
      try {
        const response = await api.get("/api/Class/GetAll");
        setListClass(response?.data);
        totalcourse(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    if (!category) {
      getAll();
    } else {
      getRecomendedClass();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  // filter the list and slice it based on the current page and number of items per page
  const ITEMS_PER_PAGE = item;
  const filteredList = ListClass?.filter((x) => +x.id !== +id);
  const totalPages = Math.ceil(filteredList?.length / ITEMS_PER_PAGE);
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const slicedFilteredList = filteredList?.slice(startIndex, endIndex);

  // function to handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (id) => {
    if (onclick) {
      onclick();
    }
    navigate(`/detailscourse/${id}`);
  };
  return (
    <>
      <Container sx={{ my: 5 }} maxWidth={"lg"}>
        <Grid container columns={{ xs: 2, md: 3 }}>
          {slicedFilteredList?.map((data) => (
            <Grid
              item
              xs={1}
              sm={1}
              key={data.id}
              onClick={() => handleClick(data.id)}
              sx={{
                p: { xs: 0.5, md: 2 },
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "lightgray",
                  borderRadius: "8px",
                },
              }}
            >
              <Course
                category={data.category}
                title={data.name}
                price={data.price}
                img={data.imgUrl}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Pagination
        count={Number.isNaN(totalPages) ? 0 : totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ my: 5, display: "flex", justifyContent: "center" }}
      />
    </>
  );
};
