/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../api/axios";

const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  const [ListCategory, setListCategory] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get("/api/Category/GetAll");
        setListCategory(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataContext.Provider value={{ ListCategory }}>
      {children}
    </DataContext.Provider>
  );
};
