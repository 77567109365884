/* eslint-disable */
import CategoryIcon from "@mui/icons-material/Category";
import ClassIcon from "@mui/icons-material/Class";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleIcon from "@mui/icons-material/People";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
export const AdminNav = () => {
  const ListNav = [
    { Menu: "Edit Course", Icon: <ClassIcon />, Link: "course" },
    { Menu: "Edit Category", Icon: <CategoryIcon />, Link: "category" },
    { Menu: "Users", Icon: <PeopleIcon />, Link: "user" },
    { Menu: "All Invoice", Icon: <ReceiptIcon />, Link: "invoice" },
    { Menu: "Payment Method", Icon: <PaymentsIcon />, Link: "payment" },
  ];
  const { nav } = useParams();
  return (
    <List>
      {ListNav.map((item, i) => (
        <ListItem disablePadding key={i}>
          <ListItemButton
            component={Link}
            to={`/adminpanel/${item.Link}`}
            sx={{
              backgroundColor: item.Link === nav ? "#e8e8e8" : "inherit",
            }}
          >
            <ListItemIcon>{item.Icon}</ListItemIcon>
            <ListItemText primary={item.Menu} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
