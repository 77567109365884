/* eslint-disable */
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import { useData } from "../../context/DataContext";
import { CustomAlert } from "../../utils/CustomAlert";
import { rupiah } from "../../utils/FormatIDR";

export const AdminCourse = () => {
  const [Status, setStatus] = useState("");
  const [editData, setEditData] = useState(null);
  const [ListClass, setListClass] = useState([]);
  const [open, setOpen] = useState(false);
  const { ListCategory } = useData();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    idCategory: 0,
    price: 0,
    imgUrl: "",
  });

  useEffect(() => {
    const getClass = async () => {
      try {
        const response = await api.get("/api/Class/GetAll");
        setListClass(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Status]);

  function handleDelete(id) {
    api
      .delete(`/api/Class/${id}`)
      .then(() => setStatus({ message: "Course deleted", type: "error" }))
      .catch((error) => console.log(error));
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post(`/api/Class`, formData);
      console.log(response);
      handleClose();
      setStatus({ message: "Course added successfully", type: "success" });
    } catch (error) {
      console.log(error.response);
    }
  };

  function handleEdit(data) {
    setEditData(data);
    setOpen(true);
  }
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.put(`/api/Class/${editData.id}`, editData);
      console.log(response);
      handleClose();
      setStatus({ message: "Course updated successfully", type: "info" });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      description: "",
      idCategory: 0,
      price: 0,
      imgUrl: "",
    });
    setEditData(null);
  };

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 8;
  const totalPages = Math.ceil(ListClass?.length / ITEMS_PER_PAGE);
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const slicedListClass = ListClass?.slice(startIndex, endIndex);

  // function to handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ mx: 2 }}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add Course
        </Button>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "8px",
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" align="center">
            {editData ? "Edit Course" : "Add Course"}
          </Typography>
          <Box
            component="form"
            onSubmit={editData ? handleEditSubmit : handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Typography>Select Category</Typography>
            <Select
              size="small"
              fullWidth
              name="idCategory"
              value={editData ? editData.idCategory : formData.idCategory}
              onChange={editData ? handleEditChange : handleChange}
            >
              {ListCategory.map((category, i) => (
                <MenuItem value={category.id} key={i}>
                  {category.category}
                </MenuItem>
              ))}
            </Select>
            <TextField
              margin="normal"
              fullWidth
              name="name"
              type="text"
              size="small"
              label="Name"
              value={editData ? editData.name : formData.name}
              onChange={editData ? handleEditChange : handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="description"
              type="text"
              size="small"
              label="Description"
              value={editData ? editData.description : formData.description}
              onChange={editData ? handleEditChange : handleChange}
            />

            <TextField
              margin="normal"
              fullWidth
              name="imgUrl"
              type="text"
              size="small"
              label="Image Url"
              value={editData ? editData.imgUrl : formData.imgUrl}
              onChange={editData ? handleEditChange : handleChange}
            />

            <TextField
              margin="normal"
              fullWidth
              name="price"
              type="number"
              size="small"
              label="Price"
              value={editData ? editData.price : formData.price}
              onChange={editData ? handleEditChange : handleChange}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                {editData ? "Update" : "Submit"}
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      <TableContainer sx={{ my: 1 }}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Course Name</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedListClass?.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell align="center">
                  <img src={row.imgUrl} alt="" width="50px" />
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.category}</TableCell>
                <TableCell align="center">{rupiah(row.price)}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleEdit(row)}>Edit</Button>
                  <Button onClick={() => handleDelete(row.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ my: 5, display: "flex", justifyContent: "center" }}
      />

      <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />
    </Box>
  );
};
