/* eslint-disable */
import {
  Alert,
  AppBar,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { successimg } from "../assets/img";
import api from "../api/axios";
export const SuccessEmail = () => {
  const { id_user, email } = useParams();
  const [Success, setSuccess] = useState(null);
  const [Error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(
          `/api/Users/ActivateUser?id_user=${id_user}&email=${email}`
        );
        setSuccess(response?.data);
        setError(null);
      } catch (error) {
        setError(error.response?.data);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, id_user]);

  return (
    <>
      <AppBar
        position="absolute"
        color="white"
        sx={{
          padding: { xs: "8px 20px", md: "8px 40px" },
          height: { xs: "70px", sm: "86px" },
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            component={Link}
            to="/"
          >
            <img src={require("../assets/img/logo.png")} alt="Logo" />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Language
          </Typography>
        </Toolbar>
      </AppBar>
      {Success && (
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            mt: 5,
            mb: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={successimg} alt="Success" width="225.89px" />
          <Typography
            align="center"
            sx={{ color: "#226957", fontSize: "24px", mt: 4 }}
          >
            Email Confirmation Success
          </Typography>
          <Typography align="center" sx={{ color: "#4F4F4F", mb: 4 }}>
            Thanks for confirmation your email. Please login first
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="/login"
            sx={{ width: "133px", height: "50px" }}
          >
            Login here
          </Button>
        </Container>
      )}
      {Error && (
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            mt: 5,
            mb: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Alert severity="error"> {Error.message}</Alert>
          <Button variant="contained" component={Link} to="/" sx={{ mt: 2 }}>
            Return
          </Button>
        </Container>
      )}
    </>
  );
};
