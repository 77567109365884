/* eslint-disable */
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import { useAuth } from "../context/AuthContext";
import { AdminPanel } from "../pages/AdminPanel";
import { CheckoutPage } from "../pages/CheckoutPage";
import { DetailsCoursePage } from "../pages/DetailsCoursePage";
import DetailsInvoice from "../pages/DetailsInvoice";
import Invoice from "../pages/Invoice";
import { LandingPage } from "../pages/LandingPage";
import { MenuCoursePage } from "../pages/MenuCoursePage";
import { MyClassPage } from "../pages/MyClassPage";
import { NewPassword } from "../pages/NewPassword";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import { SuccessEmail } from "../pages/SuccessEmail";
import { SuccessPurchasePage } from "../pages/SuccessPurchasePage";
import { Profile } from "../pages/Profile";
import ScrollToTop from "../utils/ScrollToTop";

export const RouteConfig = () => {
  const { user, token } = useAuth();
  // Fungsi ini akan mengembalikan sebuah component jika pengguna memiliki role "admin"
  const AdminRoute = ({ element }) => {
    return user?.role === "admin" ? element : <Navigate to="/" />;
  };

  // Fungsi ini akan mengembalikan sebuah component jika pengguna sudah login
  const PrivateRoute = ({ element }) => {
    return token ? element : <Navigate to="/login" />;
  };

  const PublicRoute = ({ element }) => {
    return !token ? element : <Navigate to="/" />;
  };
  return (
    <>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/login" element={<PublicRoute element={<SignIn />} />} />
          <Route
            path="/register"
            element={<PublicRoute element={<SignUp />} />}
          />
          <Route
            path="/forgotpassword"
            element={<PublicRoute element={<NewPassword page="email" />} />}
          />
          <Route
            path="/newpassword/:email/:token"
            element={
              <PublicRoute element={<NewPassword page="newPassword" />} />
            }
          />
          <Route
            path="/category/:id"
            element={<PrivateRoute element={<MenuCoursePage />} />}
          />
          <Route
            path="/detailscourse/:id"
            element={<PrivateRoute element={<DetailsCoursePage />} />}
          />
          <Route
            path="/invoice"
            element={<PrivateRoute element={<Invoice />} />}
          />
          <Route
            path="/detailsinvoice/:no_invoice"
            element={<PrivateRoute element={<DetailsInvoice />} />}
          />
          <Route
            path="/checkout"
            element={<PrivateRoute element={<CheckoutPage />} />}
          />
          <Route
            path="/myclass"
            index
            element={<PrivateRoute element={<MyClassPage />} />}
          />
          <Route
            path="/profile"
            index
            element={<PrivateRoute element={<Profile />} />}
          />
          <Route
            path="/adminpanel/:nav"
            index
            element={<AdminRoute element={<AdminPanel />} />}
          />
          <Route
            path="/successpurchase"
            index
            element={<PrivateRoute element={<SuccessPurchasePage />} />}
          />
          <Route
            path="/activation/:email/:id_user"
            element={<PublicRoute element={<SuccessEmail />} />}
          />
        </Routes>
      </ScrollToTop>
    </>
  );
};
