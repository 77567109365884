/* eslint-disable */
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { useAuth } from "../context/AuthContext";
export const Profile = () => {
  const { user } = useAuth();
  return (
    <Box sx={{ mx: 2 }}>
      <Grid container columns={{ md: 12 }}>
        <Grid item xs={2}>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  backgroundColor: "#e8e8e8",
                }}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={10}>
          <Box
            sx={{
              backgroundColor: "#226957",
              color: "#ffffff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mx: 5,
              p: 10,
              borderRadius: "8px",
            }}
          >
            <Avatar sx={{ width: 150, height: 150, mb: 4 }} />
            <Typography variant="h5" sx={{ mb: 1, fontWeight: "bold" }}>
              {user?.nama.toUpperCase()}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              ID : {user?.id}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Hak Akses : {user?.role}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
