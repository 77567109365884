/* eslint-disable */
import {
  Box,
  Button,
  Modal,
  Pagination,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import { CustomAlert } from "../../utils/CustomAlert";

export const AdminPayment = () => {
  const [Status, setStatus] = useState("");
  const [ListPayment, setListPayment] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [formData, setFormData] = useState({
    payment: "",
    is_active: false,
    img_url: "",
  });
  // console.log(formData);
  // eslint-disable-next-line no-unused-vars
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const getPayment = async () => {
      try {
        const response = await api.get("/api/Payment/GetAll");
        setListPayment(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Status]);

  const handleEditClick = (payment) => {
    setSelectedPayment(payment);
    setOpen(true);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await api.put(
        `/api/Payment/${selectedPayment.id}`,
        selectedPayment
      );
      console.log(response);
      setStatus({ message: "Updated Payment successfully", type: "info" });
      handleClose();
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedPayment({ ...selectedPayment, [name]: value });
  };

  function handleDelete(id) {
    api
      .delete(`/api/Payment/${id}`)
      .then(() =>
        setStatus({ message: "Payment deleted successfully", type: "error" })
      )
      .catch((error) => console.log(error));
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(`/api/Payment`, formData);
      console.log(response);
      setStatus({ message: "Added Payment successfully", type: "success" });
      handleClose();
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };
  const handleEditToggle = (e) => {
    const { name, checked } = e.target;
    setSelectedPayment({ ...selectedPayment, [name]: checked });
  };
  const handleClose = () => {
    setOpen(false);
    setFormData({
      payment: "",
      is_active: false,
      img_url: "",
    });
    setSelectedPayment(null);
  };

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 8;
  const totalPages = Math.ceil(ListPayment?.length / ITEMS_PER_PAGE);
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const slicedListPayment = ListPayment?.slice(startIndex, endIndex);

  // function to handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFormData({ ...formData, img_url: reader.result });
    };
    setImageFile(file);
  };
  const handleImageEditUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedPayment({ ...selectedPayment, img_url: reader.result });
    };
    setImageFile(file);
  };
  return (
    <Box sx={{ mx: 2 }}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add Payment
        </Button>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "8px",
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" align="center">
            {selectedPayment ? "Edit Payment" : "Add Payment"}
          </Typography>
          <Box
            component="form"
            onSubmit={selectedPayment ? handleUpdate : handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              name="payment"
              type="text"
              size="small"
              label="Payment Method"
              value={
                selectedPayment ? selectedPayment.payment : formData.payment
              }
              onChange={selectedPayment ? handleEditChange : handleChange}
            />

            <TextField
              margin="normal"
              fullWidth
              type="file"
              size="small"
              onChange={
                selectedPayment ? handleImageEditUpload : handleImageUpload
              }
            />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 3,
                justifyContent: "flex-end",
              }}
            >
              <Switch
                name="is_active"
                checked={
                  selectedPayment
                    ? selectedPayment.is_active
                    : formData.is_active
                }
                onChange={selectedPayment ? handleEditToggle : handleToggle}
              />
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                Enable
              </Typography>
              <Button type="submit" variant="contained">
                {selectedPayment ? "Update" : "Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <TableContainer sx={{ my: 1 }}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="center">Icon</TableCell>
              <TableCell align="center">Payment</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedListPayment?.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell align="center">
                  <img src={row.img_url} alt="" width="40px" />
                </TableCell>
                <TableCell align="center">{row.payment}</TableCell>
                <TableCell align="center">
                  {row.is_active ? "Active" : "Inactive"}
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleEditClick(row)}>Edit</Button>
                  <Button onClick={() => handleDelete(row.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ my: 5, display: "flex", justifyContent: "center" }}
      />
      <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />
    </Box>
  );
};
