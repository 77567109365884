import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

// Set headers for all requests
api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;
api.defaults.headers.post["Content-Type"] = "application/json";

export default api;
