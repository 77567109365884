/* eslint-disable */
import { Alert, Snackbar } from "@mui/material";
import React from "react";

export const CustomAlert = ({ Status, setStatus }) => {
  return (
    <Snackbar
      open={Status !== ""}
      autoHideDuration={3000}
      onClose={() => setStatus("")}
    >
      <Alert
        onClose={() => setStatus("")}
        severity={Status.type}
        sx={{ width: "100%" }}
        variant="filled"
      >
        {Status.message}
      </Alert>
    </Snackbar>
  );
};
