/* eslint-disable */
import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Footer } from "../components/Footer";
import { RecomendedClass } from "../components/RecomendedClass";
import api from "../api/axios";

export const MenuCoursePage = () => {
  const { id } = useParams();
  const [SelectedCategory, setSelectedCategory] = useState(null);
  useEffect(() => {
    const getSelectedCategory = async () => {
      try {
        const response = await api.get(`/api/Category/GetById?id=${id}`);
        setSelectedCategory(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    getSelectedCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      <img
        src={SelectedCategory?.bigImgUrl}
        style={{ objectFit: "cover", width: "100%" }}
        alt="english"
      />
      <Box padding={6}>
        <Typography variant="h5">{SelectedCategory?.category}</Typography>
        <Typography variant="body1" marginY={2} color={"gray"}>
          {SelectedCategory?.description}
        </Typography>
      </Box>
      <Divider />
      <RecomendedClass item={6} category={id} />
      <Footer />
    </div>
  );
};
