/* eslint-disable */
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../api/axios";
import { Footer } from "../components/Footer";
import { useAuth } from "../context/AuthContext";
import { rupiah } from "../utils/FormatIDR";

export default function Invoice() {
  const [ListInvoice, setListInvoice] = useState([]);
  const { user } = useAuth();
  useEffect(() => {
    const getInvoice = async () => {
      try {
        const response = await api.get(`/api/Invoice/${user?.id}`);
        setListInvoice(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <>
      <Box sx={{ mx: { xs: 2, md: 10 }, my: 5, mb: 15, minHeight: "40vh" }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>Home</Typography>
          <Typography color="secondary">Invoice</Typography>
        </Breadcrumbs>
        <Typography variant="h5" sx={{ my: 2 }}>
          Menu Invoice
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell align="center">No Invoice</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Total Course</TableCell>
                <TableCell align="center">Total Price</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ListInvoice?.reverse().map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell align="center">{row.no_invoice}</TableCell>
                  <TableCell align="center">
                    {moment(row.created_at).format("D MMMM YYYY")}
                  </TableCell>
                  <TableCell align="center">{row.qty}</TableCell>
                  <TableCell align="center">
                    {rupiah(row.total_price)}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      component={Link}
                      to={`/detailsinvoice/${row.no_invoice}`}
                      sx={{ px: 6, py: 0.5 }}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
    </>
  );
}
