/* eslint-disable */
import { Box, Typography } from "@mui/material";
import React from "react";
import banner from "../../assets/img/banner.png";

export const Banner = () => {
  return (
    <Box
      sx={{
        height: "294px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundImage: `url(${banner})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Typography variant="h4" textAlign="center" color="#FFFFFF" maxWidth="sm">
        Learn different languages ​​to hone your communication skills
      </Typography>
      <Typography
        variant="h6"
        textAlign="center"
        color="#FFFFFF"
        maxWidth="md"
        marginTop="20px"
      >
        All the languages ​​you are looking for are available here, so what are
        you waiting for and immediately improve your language skills
      </Typography>
    </Box>
  );
};
