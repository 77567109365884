import { igIcon, mailIcon, phoneIcon, teleIcon, ytIcon } from "../img/contact";

export const listContact = [
  {
    id: "1",
    name: "Whatsapp",
    img: phoneIcon,
    to: "http://wa.me/6285171727188",
  },
  {
    id: "2",
    name: "Instagram",
    img: igIcon,
    to: "http://instagram.com",
  },
  {
    id: "3",
    name: "Youtube",
    img: ytIcon,
    to: "http://youtube.com",
  },
  {
    id: "4",
    name: "Telegram",
    img: teleIcon,
    to: "http://t.me",
  },
  {
    id: "5",
    name: "Email",
    img: mailIcon,
    to: "mailto:syihabx@gmail.com",
  },
];
