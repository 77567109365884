/* eslint-disable */
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export const MobileNav = ({ onLogoutClick }) => {
  const { user } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <Box component="nav">
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {user ? (
          <Box>
            <MenuItem
              component={Link}
              to="/checkout"
              color="primary"
              onClick={handleCloseNavMenu}
            >
              Cart
            </MenuItem>
            <MenuItem
              variant="text"
              sx={{ mr: { xs: 1, md: 2 } }}
              component={Link}
              to="/myclass"
              onClick={handleCloseNavMenu}
            >
              My Class
            </MenuItem>
            <MenuItem
              variant="text"
              component={Link}
              to="/invoice"
              onClick={handleCloseNavMenu}
            >
              Invoice
            </MenuItem>
            <MenuItem
              component={Link}
              to="/profile"
              color="primary"
              onClick={handleCloseNavMenu}
            >
              Profile
            </MenuItem>
            {user?.role === "admin" && (
              <MenuItem
                component={Link}
                to="/adminpanel/course"
                color="primary"
                onClick={handleCloseNavMenu}
              >
                Admin Panel
              </MenuItem>
            )}
            <MenuItem
              component={Link}
              to="/"
              color="red"
              onClick={() => {
                onLogoutClick();
                handleCloseNavMenu();
              }}
            >
              Keluar
            </MenuItem>
          </Box>
        ) : (
          <Box>
            <MenuItem
              color="primary"
              sx={{ mr: { xs: 1, md: 2 } }}
              variant="contained"
              component={Link}
              to="/login"
              onClick={handleCloseNavMenu}
            >
              Login
            </MenuItem>
            <MenuItem
              color="secondary"
              variant="contained"
              component={Link}
              to="/register"
              onClick={handleCloseNavMenu}
            >
              Sign Up
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  );
};
