/* eslint-disable */
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { successimg } from "../assets/img";
import { Link } from "react-router-dom";
export const SuccessPurchasePage = (props) => {
  return (
    <>
      <AppBar
        position="absolute"
        color="white"
        sx={{
          padding: { xs: "8px 20px", md: "8px 40px" },
          height: { xs: "70px", sm: "86px" },
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            component={Link}
            to="/"
          >
            <img src={require("../assets/img/logo.png")} alt="Logo" />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Language
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          mt: 5,
          mb: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={successimg} alt="Success" width="225.89px" />
        <Typography
          align="center"
          sx={{ color: "#226957", fontSize: "24px", mt: 4 }}
        >
          "Purchase Successfully"
        </Typography>
        <Typography align="center" sx={{ color: "#4F4F4F", mb: 4 }}>
          Thanks to buy a course! See u in the class
        </Typography>
        <Stack direction="row" spacing={4}>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/"
            sx={{ width: "150px", height: "50px" }}
          >
            Back to Home
          </Button>
          <Button
            variant="contained"
            component={Link}
            to="/invoice"
            sx={{ width: "150px", height: "50px" }}
          >
            Open Invoice
          </Button>
        </Stack>
      </Container>
    </>
  );
};
