/* eslint-disable */
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { logo } from "../../assets/img";
import { MobileNav } from "./MobileNav";
import { DekstopNav } from "./DesktopNav";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";

export default function Header() {
  const { logout } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const handleLogoutClick = () => {
    setShowModal(true);
  };

  const handleLogoutConfirm = () => {
    logout();
    setShowModal(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        color="white"
        sx={{
          padding: { sm: "8px 20px", md: "8px 40px" },
          height: { xs: "70px", sm: "86px" },
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            component={Link}
            to="/"
          >
            <img src={logo} alt="Logo" />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Language
          </Typography>
          <Hidden smUp>
            <MobileNav onLogoutClick={handleLogoutClick} />
          </Hidden>
          <Hidden smDown>
            <DekstopNav onLogoutClick={handleLogoutClick} />
          </Hidden>
        </Toolbar>
      </AppBar>
      {/* modal konfirmasi logout */}
      <Modal open={showModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 500,
            bgcolor: "background.paper",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Apakah anda yakin untuk logout?
          </Alert>
          <Typography align="center"></Typography>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              onClick={handleModalClose}
              sx={{ mr: 4 }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleLogoutConfirm}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
