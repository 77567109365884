/* eslint-disable */
import {
  Box,
  Button,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import { CustomAlert } from "../../utils/CustomAlert";

export const AdminCategory = () => {
  const [Status, setStatus] = useState("");
  const [editData, setEditData] = useState(null);
  const [ListCategory, setListCategory] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    category: "",
    imgUrl: "",
    bigImgUrl: "",
    description: "",
  });

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await api.get("/api/Category/GetAll");
        setListCategory(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Status]);

  function handleDelete(id) {
    api
      .delete(`/api/Category/${id}`)
      .then(() =>
        setStatus({
          message: "Category deleted",
          type: "error",
        })
      )
      .catch((error) =>
        setStatus({
          message: "error",
          type: "error",
        })
      );
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post(`/api/Category`, formData);
      console.log(response);
      handleClose();
      setStatus({ message: "Category added successfully", type: "success" });
    } catch (error) {
      setStatus({
        message: "error",
        type: "error",
      });
    }
  };

  function handleEdit(data) {
    setEditData(data);
    setOpen(true);
  }
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.put(`/api/Category/${editData.id}`, editData);
      console.log(response);
      handleClose();
      setStatus({ message: "Category updated successfully", type: "info" });
    } catch (error) {
      setStatus({
        message: "error",
        type: "error",
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
    setFormData({
      category: "",
      imgUrl: "",
      bigImgUrl: "",
      description: "",
    });
    setEditData(null);
  };

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 8;
  const totalPages = Math.ceil(ListCategory?.length / ITEMS_PER_PAGE);
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const slicedListCategory = ListCategory?.slice(startIndex, endIndex);

  // function to handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ mx: 2 }}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add Category
        </Button>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "8px",
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" align="center">
            {editData ? "Edit Category" : "Add Category"}
          </Typography>
          <Box
            component="form"
            onSubmit={editData ? handleEditSubmit : handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              name="category"
              type="text"
              size="small"
              label="Category"
              value={editData ? editData.category : formData.category}
              onChange={editData ? handleEditChange : handleChange}
            />

            <TextField
              margin="normal"
              fullWidth
              name="imgUrl"
              type="text"
              size="small"
              label="Image Url"
              value={editData ? editData.imgUrl : formData.imgUrl}
              onChange={editData ? handleEditChange : handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="bigImgUrl"
              type="text"
              size="small"
              label="Big Image Url"
              value={editData ? editData.bigImgUrl : formData.bigImgUrl}
              onChange={editData ? handleEditChange : handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="description"
              type="text"
              size="small"
              label="Description"
              value={editData ? editData.description : formData.description}
              onChange={editData ? handleEditChange : handleChange}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                {editData ? "Update" : "Submit"}
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      <TableContainer sx={{ my: 1 }}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Category Name</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedListCategory?.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell align="center">
                  <img src={row.imgUrl} alt="" width="50px" />
                </TableCell>
                <TableCell align="center">{row.category}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleEdit(row)}>Edit</Button>
                  <Button onClick={() => handleDelete(row.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ my: 5, display: "flex", justifyContent: "center" }}
      />
      <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />
    </Box>
  );
};
