/* eslint-disable */
import {
  Box,
  Button,
  Modal,
  Pagination,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import { CustomAlert } from "../../utils/CustomAlert";

export const AdminUser = () => {
  const [Status, setStatus] = useState("");
  const [editData, setEditData] = useState(null);
  const [ListUser, setListUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    nama: "",
    email: "",
    password: "",
    is_active: true,
    role: "",
  });

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await api.get("/api/Users");
        setListUser(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };

    getUsers();
  }, [Status]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post(`/api/Users/create`, formData);
      console.log(response);
      setStatus({ message: "User added successfully", type: "success" });
      handleClose();
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };
  const handleEditToggle = (e) => {
    const { name, checked } = e.target;
    setEditData({ ...editData, [name]: checked });
  };
  function handleDelete(id) {
    api
      .delete(`/api/Users/${id}`)
      .then(() => setStatus({ message: "User deleted", type: "error" }))
      .catch((error) => console.log(error));
  }

  function handleEdit(data) {
    setEditData(data);
    setOpen(true);
  }
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.put(`/api/Users/${editData.id}`, editData);
      console.log(response);
      handleClose();
      setStatus({ message: "User updated successfully", type: "success" });
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setFormData({
      nama: "",
      email: "",
      password: "",
      is_active: true,
      role: "",
    });
    setEditData(null);
  };

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 8;
  const totalPages = Math.ceil(ListUser?.length / ITEMS_PER_PAGE);
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const slicedListUser = ListUser?.slice(startIndex, endIndex);

  // function to handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ mx: 2 }}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add User
        </Button>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "8px",
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" align="center">
            {editData ? "Edit User" : "Add User"}
          </Typography>
          <Box
            component="form"
            onSubmit={editData ? handleEditSubmit : handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              name="nama"
              type="text"
              size="small"
              label="Name"
              value={editData ? editData.nama : formData.nama}
              onChange={editData ? handleEditChange : handleChange}
            />

            <TextField
              margin="normal"
              fullWidth
              name="email"
              type="email"
              size="small"
              label="Email"
              value={editData ? editData.email : formData.email}
              onChange={editData ? handleEditChange : handleChange}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              type="text"
              size="small"
              label="Password"
              value={editData ? editData.password : formData.password}
              onChange={editData ? handleEditChange : handleChange}
            />

            <TextField
              margin="normal"
              fullWidth
              name="role"
              type="text"
              size="small"
              label="Role"
              value={editData ? editData.role : formData.role}
              onChange={editData ? handleEditChange : handleChange}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                mt: 3,
                justifyContent: "flex-end",
              }}
            >
              <Switch
                name="is_active"
                checked={editData ? editData.is_active : formData.is_active}
                onChange={editData ? handleEditToggle : handleToggle}
              />
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                Active
              </Typography>
              <Button type="submit" variant="contained">
                {editData ? "Update" : "Submit"}
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      <TableContainer sx={{ my: 1 }}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedListUser?.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell align="center">{row.nama}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">
                  {row.is_active ? "Active" : "Inactive"}
                </TableCell>
                <TableCell align="center">{row.role}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleEdit(row)}>Edit</Button>
                  <Button onClick={() => handleDelete(row.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ my: 5, display: "flex", justifyContent: "center" }}
      />
      <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />
    </Box>
  );
};
