/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import api from "../api/axios";
import { useAuth } from "../context/AuthContext";
import { rupiah } from "../utils/FormatIDR";
import { CheckoutContext } from "../context/CheckoutContext";
import { CustomAlert } from "../utils/CustomAlert";

export const CheckoutPage = () => {
  const Auth = useAuth();
  const { ListCheckout, setUpdateCheckout } = useContext(CheckoutContext);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [IsDialogOpen, setIsDialogOpen] = useState(false);
  const [TotalPrice, setTotalPrice] = useState(0);
  const [Status, setStatus] = useState("");

  useEffect(() => {
    const totalPrice = selectedCourses.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.price;
    }, 0);
    console.log("Total Price: ", totalPrice);
    setTotalPrice(totalPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourses]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedCourses(ListCheckout);
    } else {
      setSelectedCourses([]);
    }
  };

  const handleSelectCourse = (event, course) => {
    if (event.target.checked) {
      setSelectedCourses([...selectedCourses, course]);
    } else {
      setSelectedCourses(selectedCourses.filter((id) => id.id !== course.id));
    }
  };

  const handleDeleteCourse = async (course) => {
    try {
      const response = await api.delete(`/api/Checkout/${course.id}`);

      setStatus({ message: "Course deleted from cart", type: "error" });

      if (selectedCourses.includes(course)) {
        await setSelectedCourses(selectedCourses.filter((c) => c !== course));
      }

      await setUpdateCheckout(
        ListCheckout.filter((theCourse) => theCourse !== course)
      );
    } catch (error) {}
  };

  const handlePaynow = async () => {
    const listCourse = selectedCourses.map((item) => {
      return {
        id: item.id,
        id_class: item.id_class,
        price: item.price,
        schedule: item.schedule,
      };
    });

    const user = Auth?.user?.id;

    try {
      const body = {
        id_user: user,
        list_checkout: listCourse,
      };

      const response = await api.post(`/api/Checkout/PayNow`, body);
      setUpdateCheckout(response);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <CustomAlert Status={Status} setStatus={(e) => setStatus(e)} />
      <Stack sx={{ pb: 20, px: "55px" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCourses.length === ListCheckout.length}
                onChange={handleSelectAll}
              />
            }
            label="Pilih Semua"
          />
        </FormGroup>
        <Divider sx={{ my: 2 }} />
        {ListCheckout.map((course, i) => {
          console.log(course, i);
          return <ItemClass course={course} key={i} />;
        })}
      </Stack>
      <Price />
    </div>
  );

  function Price() {
    return (
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          py: 2,
          px: { xs: 2, sm: 8 },
          boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
          backgroundColor: "#FFFFFF",
        }}
        onClick={() => {}}
      >
        <Grid container>
          <Grid item xs={6}>
            <Stack
              direction={["column", "row"]}
              sx={{ alignItems: { xs: "-moz-initial", sm: "center" } }}
            >
              <Typography variant="body1">Total Price</Typography>
              <Button
                onClick={() => {
                  console.log(ListCheckout, "LstCheckout");
                  console.log(selectedCourses, "SelectedCourses");
                }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{ fontWeight: 700, ml: { xs: 0, sm: 2 } }}
                >
                  {rupiah(TotalPrice)}
                </Typography>
              </Button>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              textAlign: { xs: "center", sm: "right" },
            }}
          >
            <Button
              variant="contained"
              sx={{ px: 6 }}
              disabled={selectedCourses.length === 0}
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              Pay Now
            </Button>
            <SimpleDialog
              open={IsDialogOpen}
              onClose={() => {
                setIsDialogOpen(false);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  function ItemClass({ course }) {
    const isSmallScreen = useMediaQuery("(max-width:600px)");
    const date = moment(course.schedule);
    const formattedDate = date.format("dddd, D MMMM YYYY");

    return (
      <Stack>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={5} md={3}>
            <Stack position="relative" direction="row" alignItems="center">
              <Checkbox
                checked={selectedCourses
                  .map((course) => course.id)
                  .includes(course.id)}
                onChange={(event) => handleSelectCourse(event, course)}
                sx={{
                  position: isSmallScreen ? "absolute" : "static",
                  top: isSmallScreen ? 0 : "auto",
                  left: isSmallScreen ? 0 : "auto",
                }}
              />
              <img
                src={course.img_url}
                width={isSmallScreen ? "100%" : "80%"}
                alt={course.name}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={7} md={9}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="body1" color="gray">
                  {course.category}
                </Typography>
                <Typography variant="h5">{course.name}</Typography>
                <Typography
                  variant="body1"
                  color="gray"
                >{`Schedule: ${formattedDate}`}</Typography>
                <Typography variant="h6" color="secondary">
                  {rupiah(course.price)}
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  handleDeleteCourse(course);
                }}
              >
                <DeleteForeverIcon sx={{ fontSize: 32 }} color="red" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
      </Stack>
    );
  }

  function SimpleDialog(props) {
    const { onClose, open } = props;
    const [ListPayment, setListPayment] = useState([]);
    const filteredList = ListPayment.filter((x) => x.is_active === true);
    const [selectedId, setSelectedId] = useState(null);
    useEffect(() => {
      const getPayment = async () => {
        try {
          const response = await api.get("/api/Payment/GetAll");
          setListPayment(response?.data);
        } catch (error) {
          console.log(error.response?.data);
        }
      };
      getPayment();
    }, []);

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle textAlign="center" width={400}>
          Select Payment Method
        </DialogTitle>
        <Stack px={2}>
          <List>
            {filteredList?.map((data) => (
              <ListItem
                key={data.id}
                button
                onClick={() => setSelectedId(data.id)}
                style={{
                  background: selectedId === data.id ? "#ADD8E6" : "",
                }}
              >
                <ListItemIcon>
                  <img src={data.img_url} alt="" width="40px" />
                </ListItemIcon>
                <ListItemText primary={data.payment} />
              </ListItem>
            ))}
          </List>
          <br />
          <Stack direction="row" justifyContent="center">
            <Button
              variant="contained"
              sx={{ mr: 2, flexGrow: 1, py: "14px" }}
              onClick={handleClose}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              component={Link}
              to="/successpurchase"
              variant="contained"
              sx={{ flexGrow: 1 }}
              onClick={handlePaynow}
            >
              Pay Now
            </Button>
          </Stack>
          <br />
        </Stack>
      </Dialog>
    );
  }
};
