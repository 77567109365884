/* eslint-disable */
import { Box, Grid, Hidden, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { listContact } from "../assets/data/listContact";
import { useData } from "../context/DataContext";

export const Footer = () => {
  const { ListCategory } = useData();
  const navigate = useNavigate();
  return (
    <Box
      component="footer"
      sx={{
        mt: "auto",
        bgcolor: "#226957",
        minHeight: "220px",
        p: 2,
        justifyContent: "center",
        color: "#FFFFFF",
      }}
    >
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, md: 10 }}
        sx={{
          bgcolor: "#226957",
          minHeight: "220px",
          p: 2,
          justifyContent: "center",
        }}
        color="#FFFFFF"
      >
        <Hidden smDown>
          <Grid item xs={12} md={3} sx={{ mx: 2 }}>
            <Typography variant="h5" marginBottom={1}>
              About Us
            </Typography>
            <Typography
              textAlign="justify"
              sx={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px" }}
            >
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={2} sx={{ mx: 1 }}>
          <Typography variant="h5" marginBottom={1}>
            Product
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            {ListCategory?.map((data, i) => (
              <li
                key={i}
                style={{ width: "45%", cursor: "pointer" }}
                onClick={() => navigate(`/category/${data.id}`)}
              >
                {data.category}
              </li>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} md={3} sx={{ mx: 2 }}>
          <Typography variant="h5" marginBottom={1}>
            Address
          </Typography>
          <Typography
            textAlign="justify"
            marginBottom={2}
            sx={{ fontStyle: "normal", fontWeight: "400", fontSize: "14px" }}
          >
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque.
          </Typography>
          <Typography variant="h5">Contact Us</Typography>
          <Box style={{ display: "flex" }}>
            {listContact?.map((data, i) => (
              <IconButton
                size="large"
                edge="start"
                onClick={() => (window.location.href = `${data.to}`)}
                key={i}
              >
                <img src={data.img} alt={data.name} />
              </IconButton>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
