/* eslint-disable */
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api/axios";
import { Footer } from "../components/Footer";
import { rupiah } from "../utils/FormatIDR";

export default function DetailsInvoice() {
  const { no_invoice } = useParams();
  const [SelectedInvoice, setSelectedInvoice] = useState(null);
  useEffect(() => {
    const getSelectedInvoice = async () => {
      try {
        const response = await api.get(
          `/api/Invoice/Detail?no_invoice=${no_invoice}`
        );
        setSelectedInvoice(response?.data);
      } catch (error) {
        console.log(error.response?.data);
      }
    };
    getSelectedInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [no_invoice]);
  return (
    <>
      <Box sx={{ mx: { xs: 2, md: 10 }, my: 5, mb: 15 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>Home</Typography>
          <Typography>Invoice</Typography>
          <Typography color="secondary">Details Invoice</Typography>
        </Breadcrumbs>
        <Typography variant="h5" sx={{ my: 2 }}>
          Details Invoice
        </Typography>
        <Grid container columns={12} sx={{ mb: 2 }}>
          <Grid item xs={12} md={9}>
            <Box>
              <Typography variant="h5" align="left">
                No. Invoice : {SelectedInvoice?.no_invoice}
              </Typography>
              <Typography variant="h5" align="left">
                Date :{" "}
                {moment(SelectedInvoice?.created_at).format(" D MMMM YYYY")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" align="right" sx={{ mt: 2 }}>
              Total Price {rupiah(SelectedInvoice?.total_price)}
            </Typography>
          </Grid>
        </Grid>

        <TableContainer>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell align="center">Course Name</TableCell>
                <TableCell align="center">Language</TableCell>
                <TableCell align="center">Schedule</TableCell>
                <TableCell align="center">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SelectedInvoice?.list_class?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.category}</TableCell>
                  <TableCell align="center">
                    {moment(row.schedule).format("dddd, D MMMM YYYY")}
                  </TableCell>
                  <TableCell align="center">{rupiah(row.price)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
    </>
  );
}
